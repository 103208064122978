/*--------------------------------------------------------------
 * Custom chained data action
 *--------------------------------------------------------------*/

import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext,
    IGeneric,
    IAny
} from '@msdyn365-commerce/core';
import { ProductWithAttributes } from '../dyn-wishlist-items';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { getActiveWishlistItems } from './get-items-in-wishlists';
import { SimpleProduct, AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { AttributesForSelectedVariantInput, getAttributesForSelectedVariant } from '@msdyn365-commerce-modules/product-specification';

/**
 * Input class for getWishlistWithAttributes data action.
 */
export class WishlistWithAttributesInput implements IActionInput {
    public inputData: ICreateActionContext<IGeneric<IAny>>;

    constructor(inputData: ICreateActionContext<IGeneric<IAny>>) {
        this.inputData = inputData;
    }

    public getCacheKey = () => 'WishlistWithAttributes';
    public getCacheObjectType = () => 'WishlistWithAttributes';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext) => {
    return new WishlistWithAttributesInput(inputData);
};

async function getProductAttribute(product: SimpleProduct, context: IActionContext): Promise<AttributeValue[]> {
    const actionInput = new AttributesForSelectedVariantInput(product.RecordId, +context.requestContext.apiSettings.channelId, product);

    return getAttributesForSelectedVariant(actionInput, context);
}

export async function getWishlistWithAttributes(input: WishlistWithAttributesInput, ctx: IActionContext): Promise<ProductWithAttributes[]> {
    const fullProducts: FullProduct[] = await getActiveWishlistItems(input, ctx);
    const simpleProducts: SimpleProduct[] = fullProducts.map(item => item.ProductDetails);
    let productsWithAttributes: ProductWithAttributes[] = [];
    for (let i = 0; i < simpleProducts.length; i++) {
        if (simpleProducts[i]) {
            let attributes = await getProductAttribute(simpleProducts[i], ctx);
            productsWithAttributes.push({
                productDetails: simpleProducts[i],
                productSpecificationData: attributes ? attributes : null
            });
        }
    }
    return productsWithAttributes;
}

export const getWishlistWithAttributesDataAction = createObservableDataAction({
    action: <IAction<ProductWithAttributes[]>>getWishlistWithAttributes,
    input: createInput
});

export default getWishlistWithAttributesDataAction;
